import React from "react";
import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import { LinkButton } from "../../components";
import "./About.scss";

const About = () => {
  return (
    <>
      <div className="about" data-container="about">
        <div className="app__about">
          <div className="app__about_img">
            <img
              src={images.manish}
              alt="chef manish"
              sizes="100vw"
              loading="lazy"
              width="400"
              height="550"
              srcSet={`${images.manish} 500w, ${images.manish} 810w`}
            />
          </div>

          <div className="app__about_content">
            <h3>about me</h3>
            <div className="primary__text">Manish Tyagi</div>
            <p className="sub__text">
              COO and Chef{" "}
              <a href="https://aurum.ca" target="_blank" rel="noreferrer">
                @Aurum
              </a>
            </p>
            <p className="p-text full__content">
              I'm a creative, resourceful and a highly-competitive professional
              with knowledge of culinary industry, consisting of cooking, menu
              development, and HACCP training., as well as combined extensive
              experience in various types of sales including retail.
              <br />
              <br />
              I consider myself driven and goal-oriented individual, always
              looking to raise the bar and thinking out-of-the-box, with
              outstanding negotiating skills and a proven track record of
              producing exceptionally high-caliber results in every situation.
              <br />
              <br />
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur
              beatae facere saepe aperiam nam ea quibusdam corrupti quod
              accusantium cum deleniti commodi ipsa animi temporibus quaerat
              eius, ab illum culpa.
            </p>
            <LinkButton url={"/about"} title="My Story" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(About, "about", "app__whitebg");
