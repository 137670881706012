import React, { useEffect, useState } from "react";
import { PageHeader, RecipeCard } from "../../components";
import { motion } from "framer-motion";
import { sanityClient } from "../../sanitySetup";
import RecipeFilter from "../../components/RecipeFilter/RecipeFilter";
import { Link } from "react-router-dom";
import "./Recipes.scss";

const filterData = [
  "Appetizers",
  "Mains",
  "Sides",
  "Deserts",
  "Vegan",
  "Nut",
  "Diary",
  "Gluten",
  "All",
];

const Recipes = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);

  useEffect(() => {
    const query = '*[_type == "recipes"]';

    sanityClient.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard({ y: 100, opacity: 0 });

    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <motion.div
      className="app__recipes-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      {/* ================= Page Header ================== */}
      <PageHeader pageTitle="my recipes." />
      {/* ================== Recipes List ===================== */}
      <div className="app__recipes">
        <div className="container w_container">
          <div className="app__recipes-wrap">
            <RecipeFilter
              filterData={filterData}
              activeFilter={activeFilter}
              handleWorkFilter={handleWorkFilter}
            />

            <motion.div
              animate={animateCard}
              transition={{ duration: 0.5, delayChildren: 0.5 }}
              className="app__recipes-list"
            >
              {filterWork.map((data, index) => (
                <Link key={index} to={`${data._id}`}>
                  <RecipeCard recipe={data} />
                </Link>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Recipes;
