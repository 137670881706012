import React, { useEffect, useState } from "react";
import { images } from "../../constants";
import { Link } from "react-router-dom";
import { LinkButton } from "../../components";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import "./Footer.scss";
import { sanityClient } from "../../sanitySetup";

const Footer = () => {
  const [socialLinks, setSocialLinks] = useState(null);

  const convertIcons = (response) => {
    if (response) {
      return response.map((data) => {
        switch (data.icon) {
          case "FaFacebookSquare":
            return {
              ...data,
              icon: <FaFacebookSquare />,
            };
          case "FaLinkedin":
            return {
              ...data,
              icon: <FaLinkedin />,
            };
          case "FaInstagram":
            return {
              ...data,
              icon: <FaInstagram />,
            };
          case "FaYoutube":
            return {
              ...data,
              icon: <FaYoutube />,
            };

          default:
            return data;
        }
      });
    }
    return [];
  };

  useEffect(() => {
    const query = '*[_type == "socialMedia"]{icon, image, link, name, _id}';

    sanityClient
      .fetch(query)
      .then((data) => setSocialLinks(convertIcons(data)));
  }, []);

  return (
    <div className="app__footer" data-container="footer">
      <div className="container w_container">
        <div className="app__footer_content">
          <div className="foot__content_one">
            <h2>Ready to embark on a culinary journey?</h2>
            <p>
              Contact Manish to discuss your upcoming event, book a personal
              chef experience, or enquire about cooking classes.
              <br /> We look forward to bringing our passion for food &amp;
              hospitality to your table.
            </p>
            <LinkButton title="Get in Touch" url={"/contact"} />
          </div>
          <div className="foot__content_two">
            <div className="foot__logo">
              <img src={images.logo} alt="footLogo" loading="lazy" />
            </div>
            <div className="foot_links in-mobile-view">
              <h4>Quick Links</h4>
              {["About Me", "Services", "Recipes", "Blogs"].map(
                (link, index) => (
                  <Link to={link} key={index}>
                    {link}
                  </Link>
                )
              )}
            </div>
            <div className="foot_links">
              <h4>Follow Me</h4>
              {socialLinks &&
                socialLinks.map(({ icon, image, link, name, _id }) => (
                  <Link to={link} key={_id}>
                    <span className="only_desktop">{name}</span>
                    <span className="only_mobile">{icon}</span>
                  </Link>
                ))}
            </div>
            <div className="foot_links">
              <p className="cpy_right">
                &copy;2022, Chef Manish Tyagi,
                <br /> All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
