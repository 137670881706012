import React from "react";
import { motion } from "framer-motion";
import MenuItem from "./MenuItem";
import "./Navigation.scss";

const navLinks = [
  {
    label: "home",
    link: "/",
  },
  {
    label: "about me",
    link: "about",
  },
  {
    label: "services",
    link: "services",
  },
  {
    label: "recipes",
    link: "recipes",
  },
  {
    label: "blogs",
    link: "blogs",
  },
  {
    label: "contact",
    link: "contact",
  },
];

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Navigation = ({ toggle }) => {
  return (
    <motion.ul variants={variants} className="mobile_nav_items">
      {navLinks.map((item, i) => (
        <MenuItem item={item} key={i} toggle={toggle} />
      ))}
    </motion.ul>
  );
};

export default Navigation;
