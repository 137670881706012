import React from "react";
import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import { useState } from "react";
import { SectionHead, TabContent, TabItem } from "../../components";
import "./Services.scss";

const TabItems = [
  "Private Dining Experience",
  "Menu Consultation & Development",
  "Culinary Workshops & Classes",
  "Recipe Development & Food Styling",
];

const TabContents = [
  {
    imageUrl: images.tabs_img1,
    content: `Treat yourself and your guests to an exclusive dining affair with personalized menus crafted by chef Manish. From intimate gatherings to grand celebrations. Manish will curate an unforgettable culinary journey that caters to your unique tastes and preferences.`,
  },
  {
    imageUrl: images.tabs_img2,
    title:
      "Are you a resturant owner or business looking to enhance your menu?",
    content: `Chef Manish offers expert consultation to help you design a menu that reflects your vision, elevates your offerings, and delights your customers' palates. With an emphasis on seasonal and locally sourced ingredients, Chef Manish brings innovation and creativity to every dish.`,
  },
  {
    imageUrl: images.tabs_img3,
    title: "Want to learn the secrets behind Chef Manish culinary creations? ",
    content: `Join one of Chef Manish engaging and hands-on workshops, where you'll discover culinary techniques, master signature recipes, and gain insights into the art of plating and presentation. Perfect for cooking enthusiasts, aspiring chefs, or team-building activities.`,
  },
  {
    imageUrl: images.tabs_img4,
    title:
      "Need captivating and enticing recipes for your food blog, magazine or brand?",
    content: `Chef Manish provides recipe development services, creating mouthwatering dishes that not only taste amazing but also photograph beautifully. Elevate your audince with visually stunning creations.`,
  },
];

const Services = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTab = (id) => {
    setActiveTab(id);
  };
  return (
    <>
      <div className="services" data-container="services">
        <SectionHead title={"Services by Manish"} />

        <div className="app__service app__flex">
          {/* Left Side Column (Nav) */}
          <div className="app__service_nav">
            <div className="app__service_nav-pills">
              {TabItems.map((tabName, index) => (
                <TabItem
                  key={index}
                  title={tabName}
                  click={() => handleTab(index)}
                  active={activeTab === index}
                />
              ))}
            </div>
          </div>
          {/* Right Side Column (Content) */}
          <div className="app__service_content">
            <div className="pills_content">
              {TabContents.map((item, index) => (
                <TabContent
                  key={index}
                  imageUrl={item.imageUrl}
                  title={item.title}
                  content={item.content}
                  active={activeTab === index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(Services, "services", "app__defaultbg");
