import React from "react";
import { AppWrap } from "../../wrapper";
import { LinkButton } from "../../components";
import { images } from "../../constants";
import "./Hero.scss";

const Hero = () => {
  return (
    <section className="hero" data-container="hero">
      <div className="app__header">
        <div className="app__header_main-info">
          <div className="app__header_main-info-img">
            <div className="image_container">
              <img
                src={images.hero}
                alt="heroImg"
                className="hero_profile-img"
              />
            </div>
            <div className="tablet_txt">
              <p>Experience the Culinary Artistry of</p>
              <h1>
                Chef <br /> Manish Tyagi
              </h1>
            </div>
          </div>
          <div className="app__header_main-info-content">
            <p className="hello-txt">
              Experience the Culinary <br /> Artistry of
            </p>
            <h1 className="big-name">Chef Manish Tyagi</h1>
            <div className="desc-text p-text">
              I strive to create memorable dining experiences by carefully
              selecting the finest ingredients, infusing creativity into each
              dish, and presenting them with style and flair.
            </div>
            <LinkButton url={"/contact"} title="Contact Me" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppWrap(Hero, "hero");
