import logo from "../assets/logo-turquoise.png";
import icon from "../assets/turquoise-icon.png";
import blackIcon from "../assets/black_icon.png";
import bgWhite from "../assets/bgWhite.png";
import circle from "../assets/circle.svg";
import profile from "../assets/profile.png";
import manish from "../assets/manish.jpg";
import manish1 from "../assets/manish1.jpg";
import bestChef from "../assets/bestChef.png";
import tabs_img1 from "../assets/tabs_img1.png";
import tabs_img2 from "../assets/tabs_img2.png";
import tabs_img3 from "../assets/tabs_img3.png";
import tabs_img4 from "../assets/tabs_img4.png";
import amber_india from "../assets/amber_india.png";
import Rasika from "../assets/Rasika.png";
import TAj from "../assets/TAj.png";
import oberoi from "../assets/oberoi.png";
import august1five from "../assets/august1five.jpg";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import youtube from "../assets/youtube.png";
import linkedin from "../assets/linkedin.png";
import twitter from "../assets/twitter.svg";
import foodStyling from "../assets/food_styling.jpg";
import workshops from "../assets/workshops.jpg";
import consulting from "../assets/consulting.jpg";
import dining from "../assets/dining.jpeg";
import hero from "../assets/hero.jpg";
import recipe_rt from "../assets/recipe-img-rt.png";
import recipe_bg from "../assets/recipe_bg.svg";
import recipe_bg1 from "../assets/recipe_bg1.jpg";
import redPoint from "../assets/tomato_point.png";
import mission from "../assets/mission.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  logo,
  icon,
  blackIcon,
  bgWhite,
  circle,
  profile,
  manish,
  manish1,
  bestChef,
  tabs_img1,
  tabs_img2,
  tabs_img3,
  tabs_img4,
  amber_india,
  Rasika,
  TAj,
  oberoi,
  august1five,
  facebook,
  instagram,
  youtube,
  linkedin,
  twitter,
  foodStyling,
  workshops,
  consulting,
  dining,
  hero,
  recipe_rt,
  recipe_bg,
  recipe_bg1,
  redPoint,
  mission,
};
