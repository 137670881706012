import React, { useEffect, useState } from "react";
import { AppWrap } from "../../wrapper";
import { sanityClient, urlFor } from "../../sanitySetup";
import mission from "../../assets/mission.png";
import "./Experience.scss";

const Experience = () => {
  const [resturants, setResturants] = useState(null);

  useEffect(() => {
    const query = '*[_type == "resturants"] | order(_updatedAt desc)';

    sanityClient.fetch(query).then((data) => setResturants(data));
  }, []);

  return (
    <>
      <div className="experiences" data-container="experience">
        <div className="page__header_desc">
          <div className="page__header_desc-text">
            Currently working as a coo & chef{" "}
            <a href="https://aurum.ca" style={{ textDecoration: "none" }}>
              @Aurum
            </a>
            , I am passionate about making sure that systems, interfaces,
            language and graphics are human-friendly, emotive, aesthetically
            pleasing, clear, on-brand and usable — with a touch of mellow
            smoothness.
          </div>
          <div className="page__header_desc-stats">
            <div className="page__header_desc-stats-item">
              <div className="page__header_desc-stats-number">20+</div>
              <div className="page__header_desc-stats-text">
                YEARS OF EXPERIENCE
              </div>
            </div>
            <div className="page__header_desc-stats-item">
              <div className="page__header_desc-stats-number">3</div>
              <div className="page__header_desc-stats-text">AWARDS</div>
            </div>
          </div>
        </div>
        <div className="page__resturants">
          <div className="resturants__container">
            <div className="resturants_flex">
              {resturants &&
                resturants.map((data, index) => {
                  return (
                    <img
                      key={index}
                      src={urlFor(data.logo)}
                      alt={data.logo.alt}
                      sizes="100px"
                      width="100px"
                      loading="lazy"
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="exp__mission_section">
          <div className="exp__mission_container">
            <div className="exp__mission_wrap">
              <div className="exp__mission">
                <h2>
                  My mission is to promote authentic Indian food with the use of
                  fresh produce and balanced spices.
                </h2>
                <img
                  src={mission}
                  alt="my-mission"
                  width="420"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="exp__about_me">
          <div className="exp__about_wrap">
            <div className="stats_bio">
              <h3>
                I believe that food is not just sustenance; it's an art from
                that connects people &amp; evokes emotions, Let Manish take you
                on a culinary journey that celebrates the finest ingredients,
                expert techniques, &amp; a true passion for food. Want Create a
                bespoke culinary experience for you/your business?
                {/* There are an estimated 20 million eCommerce sites across the
                entire globe, with more and more being created every single day.
                Did you know that 99% of them won’t make any profit? We’ve have
                helped many to be in the other 1%. */}
                <br /> <span className="hglit">I can help you.</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(Experience, "experience", "app__whitebg");
