import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sanityClient, urlFor } from "../../sanitySetup";
import { motion } from "framer-motion";
import "./Blog.scss";

const Blog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const query = `*[_id == '${id}']{title, description, publishedOn, picture}`;

    sanityClient.fetch(query).then((data) => {
      setBlog(data[0]);
      console.log(data);
    });
  }, [id]);
  return (
    <motion.div
      className="app_single_blog-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <div className="app_single_blog">
        <div className="container w_container">
          <header>
            <div className="blog_header">
              <h1>{blog?.title}</h1>
              <div className="blog_author">
                <div className="author">Written By Chef Manish Tyagi</div>
                <div className="divider">•</div>
                <div className="date">
                  {blog?.publishedOn &&
                    new Date(blog?.publishedOn).toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                </div>
              </div>
            </div>
            <div className="blog_image">
              <div className="blog_image_frame">
                <img src={urlFor(blog?.picture)} alt="" />
              </div>
            </div>
          </header>
          <div className="blog_content-wrapper">
            <div className="body_content-body">
              <div className="content">
                <p>{blog?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Blog;
