import React, { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { images } from "../../constants";
import { NavLink } from "react-router-dom";
import { useDimensions } from "../../shared/hooks/useDimensions";
import Hamburger from "../MobileNav/Hamburger";
import Navigation from "../MobileNav/Navigation";
import "./Navbar.scss";

const navLinks = [
  {
    label: "home.",
    link: "/",
  },
  {
    label: "about me.",
    link: "about",
  },
  {
    label: "services.",
    link: "services",
  },
  {
    label: "recipes.",
    link: "recipes",
  },
  {
    label: "blogs.",
    link: "blogs",
  },
  {
    label: "contact.",
    link: "contact",
  },
];

const variants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0 at 10px 10px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const Navbar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <nav className="app__navbar" data-component="navbar">
      <div className="container w_container">
        <div className="navbar__wrap">
          <div className="app__navbar-logo">
            <NavLink to="/">
              <img src={images.icon} alt="logo" />
            </NavLink>
          </div>
          <ul className="app__navbar-links">
            {navLinks.map((item) => (
              <li className="app__flex p-text" key={`nav-${item.label}`}>
                <NavLink to={item.link}>{item.label}</NavLink>
              </li>
            ))}
          </ul>
          <Hamburger toggle={() => toggleOpen()} />
        </div>
      </div>
      <motion.div
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        variants={variants}
        className="mobile_navbar-menus"
      >
        <Navigation toggle={() => toggleOpen()} />
      </motion.div>
    </nav>
  );
};

export default Navbar;
