import React, { useEffect, useState } from "react";
import "./SectionHead.scss";

const SectionHead = ({ title, subtitle }) => {
  const [lastWord, setLastWord] = useState(null);
  const [restStr, setRestStr] = useState(null);

  useEffect(() => {
    if (title) {
      const words = title?.split(" ");
      setLastWord(words.pop());
      setRestStr(words.join(" "));
    }
  }, [title]);

  return (
    <>
      <h2 className="section_head-title" data-component="section-header">
        {restStr} <span>{lastWord}</span>
        {subtitle && (
          <p className="section_head-subtitle text-center">{subtitle}</p>
        )}
      </h2>
    </>
  );
};

export default SectionHead;
