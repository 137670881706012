import React from "react";
import { Link } from "react-router-dom";
import { ImArrowUpRight2 } from "react-icons/im";
import "./LinkButton.scss";

const LinkButton = ({ url, title, type }) => {
  let renderedButton = <button data-component="cta-button">{title}</button>;

  switch (type) {
    case "link":
      renderedButton = (
        <Link to={url} className="link__button">
          <span>{title}</span> <ImArrowUpRight2 />
        </Link>
      );
      break;
    case "button":
      renderedButton = <button className="submit__button">{title}</button>;
      break;
    default:
      renderedButton = (
        <Link to={url} className="link__button">
          <span>{title}</span> <ImArrowUpRight2 />
        </Link>
      );
      break;
  }
  return <>{renderedButton}</>;
};

export default LinkButton;
