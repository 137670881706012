import React from "react";
import "./TextImage.scss";

const TextImage = ({ isRight, title, subTitle, desc, imgUrl }) => {
  return (
    <div className="textImage_wrap" data-component="text-image">
      <div className={`content_wrapper ${isRight ? "is-right" : ""}`}>
        <div className="image_wrapper">
          <div className="visual_wrapper">
            <img src={imgUrl} alt="recipe" />
          </div>
        </div>
        <div className="copy_wrapper">
          <h3 className="title">{title}</h3>
          {subTitle ? <h5 className="subTitle">{subTitle}</h5> : null}
          <p className="copy">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default TextImage;
