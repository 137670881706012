import React from "react";

const AppWrap = (Component, idName, classNames) =>
  function HOC() {
    return (
      <div id={idName} className={`app__container ${classNames}`}>
        {/* <SocialMediaIcons /> */}

        <div className="app__wrapper app__flex">
          <div className="container w_container">
            <Component />
          </div>

          {/* <div className="copyright">
            <p className="p-text">@2022 Ashish</p>
            <p className="p-text">No Copy Rights</p>
          </div> */}
        </div>

        {/* <NavigationDots active={idName} /> */}
      </div>
    );
  };

export default AppWrap;
