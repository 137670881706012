import React from "react";
import { LinkButton, InputBox, PageHeader } from "../../components";
import { images } from "../../constants";
import { motion } from "framer-motion";
import "./Contact.scss";

const Contact = () => {
  const desc = `Lets talk about your project. Send me a message and I will be in touch with you. `;
  return (
    <motion.div
      className="app__contact_page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <PageHeader pageTitle={"contact me."} pageDesc={desc} />
      <div className="app__contact_wrap">
        <div className="container w_container">
          <div className="contact">
            <div className="contact_form">
              <h2>Drop me a line.</h2>
              <form>
                <InputBox
                  type={"text"}
                  title={"Your Name"}
                  placeholder={"Please enter your name."}
                />
                <InputBox
                  type={"email"}
                  title={"Email"}
                  placeholder={"Please enter your email."}
                />
                <InputBox
                  type={"select"}
                  title={"Subject"}
                  placeholder={"Please enter your name."}
                >
                  <option>Just saying hi</option>
                </InputBox>
                <InputBox
                  type={"textarea"}
                  title={"Message"}
                  placeholder={"Enter your message here."}
                />
                <div style={{ textAlign: "center" }}>
                  <LinkButton title="Send Contact Request Now" type="button" />
                </div>
              </form>
            </div>
            <div className="contact_follow">
              <h2>Or follow me on</h2>
              <div className="social__links_cards">
                <a
                  href="www.google.com"
                  target="_blank"
                  className="social__links_card"
                >
                  <img
                    src={images.facebook}
                    alt="facebook"
                    width="40"
                    loading="lazy"
                  />
                  <span>Facebook</span>
                </a>
                <a
                  href="www.google.com"
                  target="_blank"
                  className="social__links_card"
                >
                  <img
                    src={images.instagram}
                    alt="instagram"
                    width="40"
                    loading="lazy"
                  />
                  <span>Instagram</span>
                </a>
                <a
                  href="www.google.com"
                  target="_blank"
                  className="social__links_card"
                >
                  <img
                    src={images.linkedin}
                    alt="linkedin"
                    width="40"
                    loading="lazy"
                  />
                  <span>LinkedIn</span>
                </a>
                <a
                  href="www.google.com"
                  target="_blank"
                  className="social__links_card"
                >
                  <img
                    src={images.twitter}
                    alt="twitter"
                    width="40"
                    loading="lazy"
                  />
                  <span>Twitter</span>
                </a>
                <a
                  href="www.google.com"
                  target="_blank"
                  className="social__links_card"
                >
                  <img
                    src={images.youtube}
                    alt="youtube"
                    width="40"
                    loading="lazy"
                  />
                  <span>Youtube</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
