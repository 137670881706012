import React from "react";
import "./TabContent.scss";

const TabContent = ({ imageUrl, title, content, active }) => {
  return (
    <div
      className={active ? "content_pane_active" : "content_pane"}
      data-component="tab-content"
    >
      <div className="pane__img">
        <img src={imageUrl} alt="pane1" />
      </div>
      <div className="pane__content">
        {title ? <h6>{title}</h6> : null}
        <p>{content}</p>
      </div>
    </div>
  );
};

export default TabContent;
