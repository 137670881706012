import React from "react";
import { urlFor } from "../../sanitySetup";
import { motion } from "framer-motion";
import "./RecipeCard.scss";

const RecipeCard = ({ recipe }) => {
  return (
    <motion.div className="app__recipe--item" layoutId={recipe._id}>
      <div className="recipe-image">
        <img
          src={urlFor(recipe.thumbnail)}
          alt={recipe.title}
          sizes="(max-width: 759px) 93vw, (max-width: 767px) 706px, (max-width: 1439px) 29vw, 351.359375px"
          className="recipe-img"
        />
      </div>
      <div className="recipe-info">
        <div className="recipe-title">{recipe.title}</div>
        <div className="recipe-desc">{recipe.description}</div>
      </div>
    </motion.div>
  );
};

export default RecipeCard;
