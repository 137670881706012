import React, { useEffect, useState } from "react";
import { Blog, PageHeader } from "../../components";
import { Link } from "react-router-dom";
import { sanityClient } from "../../sanitySetup";
import { motion } from "framer-motion";
import "./Blogs.scss";

const Blogs = () => {
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    const query =
      '*[_type == "blogs"]{title, description, picture, publishedOn, _id}';
    sanityClient.fetch(query).then((data) => setBlogs(data));
  }, []);
  return (
    <motion.div
      className="app__blogs-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <PageHeader pageTitle="blogs to read." />
      <div className="app__blogs">
        <div className="container w_container">
          <div className="app__blog-list">
            {blogs &&
              blogs.map((blog) => (
                <Link to={`${blog._id}`} key={blog._id}>
                  <Blog data={blog} />
                </Link>
              ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Blogs;
