import React, { useEffect, useState } from "react";
import { AppWrap } from "../../wrapper";
import { Blog, LinkButton, SectionHead } from "../../components";
import { sanityClient } from "../../sanitySetup";
import { Link } from "react-router-dom";
import "./Blogs.scss";

const Blogs = () => {
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    const query =
      '*[_type == "blogs"]{title, description, picture, publishedOn, _id}';
    sanityClient.fetch(query).then((data) => setBlogs(data));
  }, []);
  return (
    <>
      <div className="blogs" data-container="blogs">
        <SectionHead title={"Read Latest on Manish"} />

        <div className="app__blogs-container">
          {blogs &&
            blogs.map((blog) => {
              return (
                <Link
                  key={blog._id}
                  to={`/blogs/${blog._id}`}
                  className="app__blog-item"
                >
                  <Blog data={blog} />
                </Link>
              );
            })}
        </div>
        <div className="app__blogs_foot">
          <LinkButton url={"/blogs"} title="More on blogs" />
        </div>
      </div>
    </>
  );
};

export default AppWrap(Blogs, "blogs", "app__whitebg");
