import React from "react";
import { motion } from "framer-motion";
import "./RecipeFilter.scss";

const RecipeFilter = ({ filterData, activeFilter, handleWorkFilter }) => {
  return (
    <>
      <div className="app__recipes-filter" data-component="filter">
        {filterData.map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__recipes-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {activeFilter === item && (
              <motion.div layoutId="active-pill" className="activePill" />
            )}
            <span className="activePillLabel">{item}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default RecipeFilter;
