import React from "react";
import { PageHeader, TextImage } from "../../components";
import { images } from "../../constants";
import { motion } from "framer-motion";
import "./Services.scss";

const DATA = [
  {
    title: "Private Dining Experience",
    desc: "Treat yourself and your guests to an exclusive dining affair with personalized menus crafted by chef Manish. From intimate gatherings to grand celebrations. Manish will curate an unforgettable culinary journey that caters to your unique tastes and preferences.",
    imageUrl: images.dining,
  },
  {
    title: "Menu Consultation & Development",
    subTitle:
      "Are you a resturant owner or business looking to enhance your menu?",
    desc: "Chef Manish offers expert consultation to help you design a menu that reflects your vision, elevates your offerings, and delights your customers' palates. With an emphasis on seasonal and locally sourced ingredients, Chef Manish brings innovation and creativity to every dish.",
    imageUrl: images.consulting,
  },
  {
    title: "Culinary Workshops & Classes",
    subTitle:
      "Want to learn the secrets behind Chef Manish culinary creations?",
    desc: "Join one of Chef Manish engaging and hands-on workshops, where you'll discover culinary techniques, master signature recipes, and gain insights into the art of plating and presentation. Perfect for cooking enthusiasts, aspiring chefs, or team-building activities.",
    imageUrl: images.workshops,
  },
  {
    title: "Recipe Development & Food Styling",
    subTitle:
      "Need captivating and enticing recipes for your food blog, magazine or brand?",
    desc: "Chef Manish provides recipe development services, creating mouthwatering dishes that not only taste amazing but also photograph beautifully. Elevate your audince with visually stunning creations.",
    imageUrl: images.foodStyling,
  },
];

const Services = () => {
  return (
    <motion.div
      className="app__services-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      {/* ================= Page Header ================== */}
      <PageHeader pageTitle="my services." />
      <div className="app__services">
        <div className="container w_container">
          <div className="app__services-wrap">
            {DATA
              ? DATA.map((service, index) => (
                  <TextImage
                    key={index}
                    title={service.title}
                    subTitle={service.subTitle}
                    desc={service.desc}
                    imgUrl={service.imageUrl}
                    isRight={index % 2 === 0 ? true : false}
                  />
                ))
              : "No Data Found!"}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Services;
