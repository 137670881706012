import React, { useEffect, useState } from "react";
import { LinkButton, RecipeCard, SectionHead } from "../../components";
import { AppWrap } from "../../wrapper";
import { sanityClient } from "../../sanitySetup";
import { Link } from "react-router-dom";
import "./Works.scss";

const Works = () => {
  const [recipes, setRecipes] = useState(null);

  const getSubsetOfObjects = (arrObj) => {
    const minObjs = 3;
    const maxObj = 6;

    let subSetSize = 0;
    if (arrObj.length <= minObjs || arrObj.length < maxObj) {
      subSetSize = minObjs;
    } else if (arrObj.length >= maxObj) {
      subSetSize = maxObj;
    }

    return arrObj.slice(0, subSetSize);
  };

  useEffect(() => {
    const query = '*[_type == "recipes"]';

    sanityClient.fetch(query).then((data) => {
      data = getSubsetOfObjects(data);
      setRecipes(data);
    });
  }, []);

  return (
    <>
      <div className="works" data-container="works">
        <SectionHead
          title={"Kitchen by Manish"}
          subtitle={"Recipes like gold dust in your hand"}
        />
        <div className="app__works">
          <div className="app__works-wrapper">
            <div className="app__works--items">
              {recipes &&
                recipes.map((data, index) => (
                  <Link key={index} to={`recipes/${data._id}`}>
                    <RecipeCard recipe={data} />
                  </Link>
                ))}
            </div>
          </div>
        </div>
        <div className="app__works_foot">
          <LinkButton url={"/recipes"} title="Find your perfect Recipe" />
        </div>
      </div>
    </>
  );
};

export default AppWrap(Works, "works", "app__whitebg");
