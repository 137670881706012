import React, { useLayoutEffect, useState } from "react";
import { AppWrap } from "../../wrapper";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import { SectionHead } from "../../components";
import { sanityClient } from "../../sanitySetup";
import "./Testimonial.scss";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 10 : -10,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 10 : -10,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 1000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Testimonial = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [testimonials, setTestimonials] = useState(null);
  const dataIndex = wrap(0, testimonials?.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  useLayoutEffect(() => {
    const query =
      '*[_type == "testimonials"]{personName, imageUrl, designation, message}';

    sanityClient.fetch(query).then((data) => {
      setTestimonials(data);
    });
  }, []);

  return (
    <>
      <div className="testimonials" data-container="testimonial">
        <SectionHead
          title={"What people say about Manish"}
          // subtitle={"See what peoples say about me!"}
        />
        <div className="app__testimonials app__flex">
          <div className="app__flex prevCTA" onClick={() => paginate(-1)}>
            <HiOutlineArrowLeft />
          </div>
          <AnimatePresence exitBeforeEnter initial={false} custom={direction}>
            {testimonials && (
              <motion.div
                key={dataIndex}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                className="testimonial__box_wrap"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);

                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
              >
                <div className="testimonial__box_item">
                  <div style={{ position: "relative" }}>
                    <div className="testimonial_circle"></div>
                    <div className="testimonial_circle-sm"></div>
                    <div className="testimonial_circle-xs"></div>
                    <img
                      src={testimonials[dataIndex]?.imageUrl}
                      alt={testimonials[dataIndex]?.personName}
                      className="testimonial__person"
                    />
                  </div>
                  <div>
                    <h5>“{testimonials[dataIndex]?.message}”</h5>
                    <div className="testimonial__person_name">
                      {testimonials[dataIndex]?.personName}
                    </div>
                    <div className="p-text testimonial__person_role">
                      {testimonials[dataIndex]?.designation}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="app__flex nextCTA" onClick={() => paginate(1)}>
            <HiOutlineArrowRight />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(Testimonial, "testimonial", "app__defaultbg");
