import React from "react";
import {
  About,
  Blogs,
  Experience,
  Hero,
  Services,
  Testimonial,
  Works,
} from "../../container";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      <Hero />
      <Experience />
      <About />
      <Services />
      <Works />
      <Testimonial />
      <Blogs />
    </motion.div>
  );
};

export default Home;
