import React from "react";
import "./InputBox.scss";

const InputBox = ({ title, placeholder, type, children }) => {
  let renderedInput = <input type="text" placeholder={placeholder} />;

  switch (type) {
    case "email":
      renderedInput = <input type="email" placeholder={placeholder} />;
      break;
    case "select":
      renderedInput = <select name="subject">{children}</select>;
      break;
    case "textarea":
      renderedInput = <textarea rows="4" placeholder={placeholder}></textarea>;
      break;
    default:
      renderedInput = <input type="text" placeholder={placeholder} />;
      break;
  }

  return (
    <div className="input__group" data-component="inputbox">
      <label>{title}</label>
      {renderedInput}
    </div>
  );
};

export default InputBox;
