import React, { useEffect, useState } from "react";
import { PageHeader } from "../../components";
import { GiLaurelsTrophy } from "react-icons/gi";
import { images } from "../../constants";
import { sanityClient, urlFor } from "../../sanitySetup";
import { motion } from "framer-motion";
import "./About.scss";

const About = () => {
  const [awards, setAwards] = useState(null);
  const [resturants, setResturants] = useState(null);

  useEffect(() => {
    const awardsQuery =
      '*[_type == "awards"]{name, givenBy, givenDt, description}';
    const resturantsQuery =
      '*[_type == "resturants"]{name, logo, slug, _id} | order(_updatedAt desc)';

    sanityClient.fetch(awardsQuery).then((data) => setAwards(data));
    sanityClient.fetch(resturantsQuery).then((data) => setResturants(data));
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      className="app__about_page"
    >
      {/* ================= Page Header ================== */}
      <PageHeader pageTitle={"about me."} />
      {/* ================== My Story ===================== */}
      <div className="app__my_story">
        <div className="container w_container">
          <div className="my_story_wrap">
            <div className="my_story_text">
              <h1>
                <span>👋 hello</span>
                <br />
                I'm manish, <br />
                coo &amp; chef @Aurum
              </h1>
              <p className="p-text">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero
                praesentium eum ex eius saepe! Velit fugiat alias impedit
                ratione, reiciendis, molestiae magnam vitae temporibus maxime
                repudiandae distinctio et porro quaerat!
              </p>
              <br />
              <p className="p-text">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero
                praesentium eum ex eius saepe! Velit fugiat alias impedit
                ratione, reiciendis, molestiae magnam vitae temporibus maxime
                repudiandae distinctio et porro quaerat!
              </p>
            </div>
            <div className="my_story_img">
              <img
                src={images.manish1}
                alt="manish_story"
                width="650px"
                loading="lazy"
              />
              <div className="img_blur"></div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== Worked with ================== */}
      <div className="app__worked_with">
        <div className="container w_container">
          <div className="worked_with_wrap">
            <h1>Worked With</h1>
            {resturants &&
              resturants.map((image) => (
                <div className="worked_with_img" key={image._id}>
                  <img
                    src={urlFor(image.logo)}
                    alt={image.name}
                    sizes="120px"
                    width="120px"
                    loading="lazy"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* ================= Awards ===================== */}
      <div className="app__about_awards">
        <div className="container w_container">
          <h2 className="head-text">Awards &amp; Recognition</h2>
          <div className="awards">
            {awards &&
              awards.map((award, i) => (
                <div className="award_box" key={i}>
                  <GiLaurelsTrophy />
                  <h4>{award.name}</h4>
                  <p className="text">{award.description}</p>
                  <p className="p-text">
                    {award.givenBy} <br /> {award.givenDt}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
