import React from "react";
import { motion } from "framer-motion";
import { urlFor } from "../../sanitySetup";
import "./Blog.scss";

const Blog = ({ data: { title, description, picture, publishedOn, _id } }) => {
  return (
    <motion.div
      whileInView={{
        opacity: 1,
      }}
      transition={{ duration: 0.9, type: "tween" }}
      className="app__blog-item-wrap"
      data-component="blog"
    >
      <div className="app__blog-inner">
        <div className="blog__item-img">
          <img src={urlFor(picture)} alt={title} loading="lazy" />
        </div>
        <div className="blog__item-content">
          <p className="published_at p-text">
            {publishedOn &&
              new Date(publishedOn).toLocaleDateString("en-us", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
          </p>
          <h2 className="item_title bold-text">{title}</h2>
          <p className="item_desc">{description}</p>
        </div>
      </div>
    </motion.div>
  );
};

export default Blog;
