import React from "react";
import "./TabItem.scss";

const TabItem = ({ title, click, active }) => {
  return (
    <>
      <div
        className={active ? "nav__links active" : "nav__links"}
        onClick={click}
        data-component="tab-item"
      >
        <h4>{title}</h4>
      </div>
    </>
  );
};

export default TabItem;
