import React from "react";
import "./PageHeader.scss";

const PageHeader = ({ pageTitle }) => {
  return (
    <div className="page__header" data-component="page-header">
      <div className="container w_container">
        <h1 className="page__header_heading_big">{pageTitle}</h1>
      </div>
    </div>
  );
};

export default PageHeader;
