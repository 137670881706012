import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sanityClient, urlFor } from "../../sanitySetup";
import { GoStopwatch } from "react-icons/go";
import { BiDish } from "react-icons/bi";
import { BsEmojiSmile } from "react-icons/bs";
import { PiCookingPotLight } from "react-icons/pi";
import { GiCampCookingPot } from "react-icons/gi";
import { images } from "../../constants";
import { motion } from "framer-motion";
import "./Recipe.scss";

const Recipe = () => {
  const { id } = useParams();
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    const query = `*[_id == '${id}']{title, description, totalCookTime, prepTime, cookTime, recipeServing, recipeType, "ingredients": ingredients[].children[].text, coverImage, "steps": howtoMake[].children[].text, "tags": tags[]->{label}}`;

    sanityClient.fetch(query).then((data) => {
      setRecipe(data[0]);
      console.log(data);
    });
  }, [id]);

  return (
    <motion.div
      className="app_single_recipe-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
    >
      {/* ================= Page Header ================== */}
      <div className="app_single_recipe">
        <div className="container w_container">
          <div className="recipe_header">
            <h1>{recipe?.title}</h1>
          </div>
          <div className="recipe_img">
            <div className="img_wrap">
              <img src={urlFor(recipe?.coverImage)} alt={recipe?.title} />
            </div>
          </div>
          <div className="recipe_description">
            <p>{recipe?.description}</p>
          </div>
          <div className="recipe_detail">
            <ul>
              <li>
                <div className="recipe_crd">
                  <PiCookingPotLight className="recipe_crd_ico" />
                  <p className="recipe_crd_txt1">Total Cook Time</p>
                  <p className="recipe_crd_txt2">
                    {recipe?.totalCookTime} mins
                  </p>
                </div>
              </li>
              <li>
                <div className="recipe_crd">
                  <GoStopwatch className="recipe_crd_ico" />
                  <p className="recipe_crd_txt1">Prep Time</p>
                  <p className="recipe_crd_txt2">{recipe?.prepTime} mins</p>
                </div>
              </li>
              <li>
                <div className="recipe_crd">
                  <GiCampCookingPot className="recipe_crd_ico" />
                  <p className="recipe_crd_txt1">Cook Time</p>
                  <p className="recipe_crd_txt2">{recipe?.cookTime} mins</p>
                </div>
              </li>
              <li>
                <div className="recipe_crd">
                  <BiDish className="recipe_crd_ico" />
                  <p className="recipe_crd_txt1">Recipe Servings</p>
                  <p className="recipe_crd_txt2">{recipe?.recipeServing}</p>
                </div>
              </li>
              <li>
                <div className="recipe_crd">
                  <BsEmojiSmile className="recipe_crd_ico" />
                  <p className="recipe_crd_txt1">{recipe?.recipeType}</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="recipe_ings">
            <div className="ingredients_wrap">
              <img src={images.recipe_rt} alt="ings_svg" />
              <div className="ings_elem-wrp">
                <div className="ings_elem-txt">
                  <h3>Ingredients</h3>
                  <div className="ing_elements">
                    <ul>
                      {recipe?.ingredients?.map((ing, index) => (
                        <li key={index}>{ing}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="how_to_make">
            <div className="method_title">
              <h1>How to Make {recipe?.title}</h1>
            </div>
            {recipe?.steps?.map((item, index) => (
              <div key={index} className="li_content">
                <span className="li_count">{index + 1}.</span>
                <div className="li_wrap">
                  <span className="li_text">{item}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="recipe_tags">
            <div className="tags_title">Tags:</div>
            <div className="tags_scroll">
              <ul className="tags_scroll_cont">
                <li className="tags_li">
                  {recipe?.tags?.map((tag, index) => (
                    <span className="tags_li_txt" key={index}>
                      {tag.label}
                    </span>
                  ))}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Recipe;
